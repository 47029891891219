export class Permissions {

  static readonly SALES: number = 1
  static readonly LOGISTICS: number = 2
  static readonly CONTROLLING: number = 3
  static readonly FINANCE: number = 4
  static readonly SHARED: number = 5
  static readonly PURCHASE: number = 6
  static readonly SETTING: number = 7
  static readonly HR: number = 8
  static readonly PDF: number = 9
  static readonly AI: number = 111111

  static readonly SALES_PRM: number = 10
  static readonly SALES_CATALOGS: number = 11
  static readonly SALES_CUSTOMERS: number = 12
  static readonly SALES_OFFERS: number = 13
  static readonly SALES_ORDERS: number = 14
  static readonly SALES_INVOICES: number = 15
  static readonly SALES_CRM: number = 16

  static readonly LOGISTICS_PARTNER: number = 20
  static readonly LOGISTICS_STOCKLIST: number = 21
  static readonly LOGISTICS_ORDERPROCESSING: number = 22
  static readonly LOGISTICS_GOODSRECEIPT: number = 23
  static readonly LOGISTICS_GOODSISSUE: number = 24
  static readonly LOGISTICS_STORAGELOCATION: number = 26
  static readonly LOGISTICS_GOODSRETURN: number = 27
  static readonly LOGISTICS_ADMIN: number = 29

  static readonly CONTROLLING_DASHBOARD: number = 30
  static readonly CONTROLLING_ADMIN: number = 31
  static readonly CONTROLLING_SHARED: number = 32
  static readonly CONTROLLING_SALES: number = 33
  static readonly CONTROLLING_PURCHASE: 34
  static readonly CONTROLLING_OPERATIONS: 34
  static readonly CONTROLLING_ECONOMIC: 38
  static readonly CONTROLLING_FINANCE: 39

  static readonly FINANCE_BANKS: number = 40
  static readonly FINANCE_LIQUIDITY: number = 41
  static readonly FINANCE_DUNNINGS: number = 42
  static readonly FINANCE_DATEV: number = 43
  static readonly FINANCE_ACCOUNTING: number = 44

  static readonly SHARED_ARTICLES: number = 50
  static readonly SHARED_USERS: number = 51
  static readonly SHARED_BRANDS: number = 52
  static readonly SHARED_CATEGORIES: number = 53
  static readonly SHARED_CONTACTPERSONS: number = 54
  static readonly SHARED_CARRIER: number = 55
  static readonly SHARED_SETTINGS: number = 56
  static readonly SHARED_COUNTRY: number = 57
  static readonly SHARED_COMMUNICATION: number = 58

  static readonly PURCHASE_SUPPLIERS: number = 60
  static readonly PURCHASE_ORDERS: number = 61
  static readonly PURCHASE_OFFERS: number = 62

  static readonly HR_EMPLOYEES: number = 80

  static readonly PDF_LOGISTICS: number = 90
  static readonly PDF_SALES: number = 91
  static readonly PDF_PURCHASE: number = 92
  static readonly PDF_ARTICLE: number = 93
  static readonly PDF_CONTROLLING: number = 94
  static readonly PDF_MISC: number = 95

  static readonly AI_BROCHURE: number = 1111110
  static readonly AI_BROCHURE_GETCOLLECTION: number = 11111100
  static readonly AI_BROCHURE_POST: number = 11111101
  static readonly AI_BROCHURE_GETITEM: number = 11111102
  static readonly AI_BROCHURE_PATCH: number = 11111103
  static readonly AI_BROCHURE_DELETE: number = 11111104

  static readonly SALES_PRM_MAILINGS: number = 100

  static readonly SALES_CATALOGS_GETCOLLECTION: number = 110
  static readonly SALES_CATALOGS_POST: number = 111
  static readonly SALES_CATALOGS_GETITEM: number = 112
  static readonly SALES_CATALOGS_PATCH: number = 113
  static readonly SALES_CATALOGS_DELETE: number = 114

  static readonly SALES_CUSTOMERS_GETCOLLECTION: number = 120
  static readonly SALES_CUSTOMERS_POST: number = 121
  static readonly SALES_CUSTOMERS_GETITEM: number = 122
  static readonly SALES_CUSTOMERS_PATCH: number = 123
  static readonly SALES_CUSTOMERS_DELETE: number = 124

  static readonly SALES_OFFERS_GETCOLLECTION: number = 130
  static readonly SALES_OFFERS_POST: number = 131
  static readonly SALES_OFFERS_GETITEM: number = 132
  static readonly SALES_OFFERS_PATCH: number = 133
  static readonly SALES_OFFERS_DELETE: number = 134

  static readonly SALES_ORDERS_GETCOLLECTION: number = 140
  static readonly SALES_ORDERS_POST: number = 141
  static readonly SALES_ORDERS_GETITEM: number = 142
  static readonly SALES_ORDERS_PATCH: number = 143
  static readonly SALES_ORDERS_DELETE: number = 144
  static readonly SALES_ORDERS_PACKAGES: number = 145
  static readonly SALES_ORDERS_SHIPMENTS: number = 146
  static readonly SALES_ORDERS_ADMIN: number = 147

  static readonly SALES_INVOICES_GETCOLLECTION: number = 150
  static readonly SALES_INVOICES_POST: number = 151
  static readonly SALES_INVOICES_GETITEM: number = 152
  static readonly SALES_INVOICES_PATCH: number = 153
  static readonly SALES_INVOICES_DELETE: number = 154

  static readonly SALES_CRM_ACTIVITIES: number = 160
  static readonly SALES_CRM_LEADS: number = 161

  static readonly LOGISTICS_PARTNER_GETCOLLECTION: number = 200
  static readonly LOGISTICS_PARTNER_POST: number = 201
  static readonly LOGISTICS_PARTNER_GETITEM: number = 202
  static readonly LOGISTICS_PARTNER_PATCH: number = 203
  static readonly LOGISTICS_PARTNER_DELETE: number = 204

  static readonly LOGISTICS_ORDERPROCESSING_GETCOLLECTION: number = 220
  static readonly LOGISTICS_ORDERPROCESSING_POST: number = 221
  static readonly LOGISTICS_ORDERPROCESSING_GETITEM: number = 222
  static readonly LOGISTICS_ORDERPROCESSING_PATCH: number = 223
  static readonly LOGISTICS_ORDERPROCESSING_DELETE: number = 224

  static readonly LOGISTICS_GOODSRECEIPT_GETCOLLECTION: number = 230
  static readonly LOGISTICS_GOODSRECEIPT_POST: number = 231
  static readonly LOGISTICS_GOODSRECEIPT_GETITEM: number = 232
  static readonly LOGISTICS_GOODSRECEIPT_PATCH: number = 233
  static readonly LOGISTICS_GOODSRECEIPT_DELETE: number = 234

  static readonly LOGISTICS_GOODSISSUE_GETCOLLECTION: number = 240
  static readonly LOGISTICS_GOODSISSUE_POST: number = 241
  static readonly LOGISTICS_GOODSISSUE_GETITEM: number = 242
  static readonly LOGISTICS_GOODSISSUE_PATCH: number = 243
  static readonly LOGISTICS_GOODSISSUE_DELETE: number = 244

  static readonly LOGISTICS_STORAGELOCATION_GETCOLLECTION: number = 260
  static readonly LOGISTICS_STORAGELOCATION_POST: number = 261
  static readonly LOGISTICS_STORAGELOCATION_GETITEM: number = 262
  static readonly LOGISTICS_STORAGELOCATION_PATCH: number = 263
  static readonly LOGISTICS_STORAGELOCATION_DELETE: number = 264

  static readonly LOGISTICS_GOODSRETURN_GETCOLLECTION: number = 270
  static readonly LOGISTICS_GOODSRETURN_POST: number = 271
  static readonly LOGISTICS_GOODSRETURN_GETITEM: number = 272
  static readonly LOGISTICS_GOODSRETURN_PATCH: number = 273
  static readonly LOGISTICS_GOODSRETURN_DELETE: number = 274

  static readonly CONTROLLING_SHARED_ARTICLES: number = 320

  static readonly CONTROLLING_SALES_CRM: number = 330
  static readonly CONTROLLING_SALES_CUSTOMERS: number = 331
  static readonly CONTROLLING_SALES_YEARLY_OVERVIEW: number = 332
  static readonly CONTROLLING_SALES_YEARLY_MARGIN: number = 333
  static readonly CONTROLLING_SALES_ORDER_INCOME: number = 334
  static readonly CONTROLLING_SALES_COMMISSION: number = 335
  static readonly CONTROLLING_SALES_SHIPMENTS: number = 336

  static readonly CONTROLLING_PURCHASE_SUPPLIERS: number = 340
  static readonly CONTROLLING_PURCHASE_RETAILER_MANUFACTURER_COUNT: number = 341
  static readonly CONTROLLING_PURCHASE_BRANDS: number = 342
  static readonly CONTROLLING_PURCHASE_TURNOVER_RATE: number = 343
  static readonly CONTROLLING_PURCHASE_YEARLY_OVERVIEW: number = 344
  static readonly CONTROLLING_PURCHASE_ARTICLES: number = 345
  static readonly CONTROLLING_PURCHASE_MONTHLY_SHIPMENTS: number = 346

  static readonly CONTROLLING_OPERATIONS_ORDER_CONFIRMATIONS: number = 350
  static readonly CONTROLLING_OPERATIONS_STOCK: number = 351
  static readonly CONTROLLING_OPERATIONS_INVOICE_PROFORMA: number = 352

  static readonly CONTROLLING_ECONOMIC_EVALUATIONS: number = 380

  static readonly CONTROLLING_FINANCE_LIQUIDITY: number = 390

  static readonly FINANCE_BANKS_GETCOLLECTION: number = 400
  static readonly FINANCE_BANKS_PATCH: number = 403

  static readonly FINANCE_LIQUIDITY_GET: number = 410
  static readonly FINANCE_LIQUIDITY_POST: number = 411
  static readonly FINANCE_LIQUIDITY_PATCH: number = 412
  static readonly FINANCE_LIQUIDITY_DELETE: number = 413

  static readonly FINANCE_DUNNINGS_GETCOLLECTION: number = 420
  static readonly FINANCE_DUNNINGS_PATCH: number = 423

  static readonly FINANCE_ACCOUNTING_GETCOLLECTION: number = 440

  static readonly SHARED_ARTICLES_GETCOLLECTION: number = 500
  static readonly SHARED_ARTICLES_POST: number = 501
  static readonly SHARED_ARTICLES_GETITEM: number = 502
  static readonly SHARED_ARTICLES_PATCH: number = 503
  static readonly SHARED_ARTICLES_DELETE: number = 504
  static readonly SHARED_ARTICLES_PACKAGINGUNITS: number = 505
  static readonly SHARED_ARTICLES_ADMIN: number = 506

  static readonly SHARED_USERS_GETCOLLECTION: number = 510
  static readonly SHARED_USERS_POST: number = 511
  static readonly SHARED_USERS_GETITEM: number = 512
  static readonly SHARED_USERS_PATCH: number = 513
  static readonly SHARED_USERS_DELETE: number = 514

  static readonly SHARED_BRANDS_GETCOLLECTION: number = 520
  static readonly SHARED_BRANDS_POST: number = 521
  static readonly SHARED_BRANDS_GETITEM: number = 522
  static readonly SHARED_BRANDS_PATCH: number = 523
  static readonly SHARED_BRANDS_DELETE: number = 524

  static readonly SHARED_CATEGORIES_GETCOLLECTION: number = 530
  static readonly SHARED_CATEGORIES_GETITEM: number = 532

  static readonly SHARED_CONTACTPERSONS_GETCOLLECTION: number = 540
  static readonly SHARED_CONTACTPERSONS_GETITEM: number = 542
  static readonly SHARED_CONTACTPERSONS_PATCH: number = 543

  static readonly SHARED_CARRIER_GETCOLLECTION: number = 550
  static readonly SHARED_CARRIER_POST: number = 551
  static readonly SHARED_CARRIER_GETITEM: number = 552
  static readonly SHARED_CARRIER_PATCH: number = 553
  static readonly SHARED_CARRIER_DELETE: number = 554

  static readonly SHARED_SETTINGS_GETITEM: number = 560
  static readonly SHARED_SETTINGS_PATCH: number = 561

  static readonly SHARED_COUNTRY_GETCOLLECTION: number = 570
  static readonly SHARED_COUNTRY_POST: number = 571
  static readonly SHARED_COUNTRY_GETITEM: number = 572
  static readonly SHARED_COUNTRY_PATCH: number = 573
  static readonly SHARED_COUNTRY_DELETE: number = 574

  static readonly SHARED_COMMUNICATION_MESSAGES: number = 580
  static readonly SHARED_COMMUNICATION_NOTIFICATIONS: number = 581

  static readonly PURCHASE_SUPPLIERS_GETCOLLECTION: number = 601
  static readonly PURCHASE_SUPPLIERS_POST: number = 602
  static readonly PURCHASE_SUPPLIERS_GETITEM: number = 603
  static readonly PURCHASE_SUPPLIERS_PATCH: number = 604
  static readonly PURCHASE_SUPPLIERS_DELETE: number = 605

  static readonly PURCHASE_ORDERS_GETCOLLECTION: number = 610
  static readonly PURCHASE_ORDERS_POST: number = 611
  static readonly PURCHASE_ORDERS_GETITEM: number = 612
  static readonly PURCHASE_ORDERS_PATCH: number = 613
  static readonly PURCHASE_ORDERS_DELETE: number = 614
  static readonly PURCHASE_ORDERS_SHIPMENTS: number = 615

  static readonly PURCHASE_OFFERS_GETCOLLECTION: number = 620
  static readonly PURCHASE_OFFERS_POST: number = 621
  static readonly PURCHASE_OFFERS_GETITEM: number = 622
  static readonly PURCHASE_OFFERS_PATCH: number = 623
  static readonly PURCHASE_OFFERS_DELETE: number = 624

  static readonly HR_EMPLOYEES_GETCOLLECTION: number = 800
  static readonly HR_EMPLOYEES_POST: number = 801
  static readonly HR_EMPLOYEES_GETITEM: number = 802
  static readonly HR_EMPLOYEES_PATCH: number = 803
  static readonly HR_EMPLOYEES_DELETE: number = 804
  static readonly HR_EMPLOYEES_ABSENCES: number = 805

  static readonly SALES_PRM_MAILINGS_GETCOLLECTION: number = 1000
  static readonly SALES_PRM_MAILINGS_POST: number = 1001
  static readonly SALES_PRM_MAILINGS_GETITEM: number = 1002
  static readonly SALES_PRM_MAILINGS_PATCH: number = 1003
  static readonly SALES_PRM_MAILINGS_DELETE: number = 1004
  static readonly SALES_PRM_MAILINGS_ITEMS: number = 1005
  static readonly SALES_PRM_MAILINGS_RECIPIENTS: number = 1006
  static readonly SALES_PRM_MAILINGS_PREVIEW: number = 1007
  static readonly SALES_PRM_MAILINGS_DISPATCH: number = 1008
  static readonly SALES_PRM_MAILINGS_IMAGES: number = 1009

  static readonly SALES_ORDERS_PACKAGES_GETCOLLECTION: number = 1450
  static readonly SALES_ORDERS_PACKAGES_POST: number = 1451
  static readonly SALES_ORDERS_PACKAGES_GETITEM: number = 1452
  static readonly SALES_ORDERS_PACKAGES_PATCH: number = 1453
  static readonly SALES_ORDERS_PACKAGES_DELETE: number = 1454

  static readonly SALES_ORDERS_SHIPMENTS_GETCOLLECTION: number = 1460
  static readonly SALES_ORDERS_SHIPMENTS_POST: number = 1461
  static readonly SALES_ORDERS_SHIPMENTS_GETITEM: number = 146
  static readonly SALES_ORDERS_SHIPMENTS_PATCH: number = 1463
  static readonly SALES_ORDERS_SHIPMENTS_DELETE: number = 1464

  static readonly SALES_CRM_ACTIVITIES_GETCOLLECTION: number = 1600
  static readonly SALES_CRM_ACTIVITIES_POST: number = 1601
  static readonly SALES_CRM_ACTIVITIES_GETITEM: number = 1602
  static readonly SALES_CRM_ACTIVITIES_PATCH: number = 1603
  static readonly SALES_CRM_ACTIVITIES_DELETE: number = 1604

  static readonly SALES_CRM_LEADS_GETCOLLECTION: number = 1610
  static readonly SALES_CRM_LEADS_POST: number = 1611
  static readonly SALES_CRM_LEADS_GETITEM: number = 1612
  static readonly SALES_CRM_LEADS_PATCH: number = 1613
  static readonly SALES_CRM_LEADS_DELETE: number = 1614

  static readonly CONTROLLING_SALES_CRM_CONTACT_STATS: number = 3300

  static readonly SHARED_ARTICLES_PACKAGINGUNITS_GETCOLLECTION: number = 5050
  static readonly SHARED_ARTICLES_PACKAGINGUNITS_POST: number = 5051
  static readonly SHARED_ARTICLES_PACKAGINGUNITS_GETITEM: number = 5052
  static readonly SHARED_ARTICLES_PACKAGINGUNITS_PATCH: number = 5053
  static readonly SHARED_ARTICLES_PACKAGINGUNITS_DELETE: number = 5054

  static readonly SHARED_COMMUNICATION_MESSAGES_GETCOLLECTION: number = 5800
  static readonly SHARED_COMMUNICATION_MESSAGES_POST: number = 5801
  static readonly SHARED_COMMUNICATION_MESSAGES_GETITEM: number = 5802
  static readonly SHARED_COMMUNICATION_MESSAGES_PATCH: number = 5803
  static readonly SHARED_COMMUNICATION_MESSAGES_DELETE: number = 5804

  static readonly SHARED_COMMUNICATION_NOTIFICATIONS_GETCOLLECTION: number = 5810
  static readonly SHARED_COMMUNICATION_NOTIFICATIONS_POST: number = 5811
  static readonly SHARED_COMMUNICATION_NOTIFICATIONS_GETITEM: number = 5812
  static readonly SHARED_COMMUNICATION_NOTIFICATIONS_PATCH: number = 5813
  static readonly SHARED_COMMUNICATION_NOTIFICATIONS_DELETE: number = 5814

  static readonly PURCHASE_ORDERS_SHIPMENTS_GETCOLLECTION: number = 6150
  static readonly PURCHASE_ORDERS_SHIPMENTS_POST: number = 6151
  static readonly PURCHASE_ORDERS_SHIPMENTS_GETITEM: number = 6152
  static readonly PURCHASE_ORDERS_SHIPMENTS_PATCH: number = 6153
  static readonly PURCHASE_ORDERS_SHIPMENTS_DELETE: number = 6154

  static readonly HR_EMPLOYEES_ABSENCES_GETCOLLECTION: number = 8050
  static readonly HR_EMPLOYEES_ABSENCES_POST: number = 8051
  static readonly HR_EMPLOYEES_ABSENCES_GETITEM: number = 8052
  static readonly HR_EMPLOYEES_ABSENCES_PATCH: number = 8053
  static readonly HR_EMPLOYEES_ABSENCES_DELETE: number = 8054

  static readonly SALES_PRM_MAILINGS_SUGGESTED: number = 10000

  static readonly SALES_PRM_MAILINGS_ITEMS_GETCOLLECTION: number = 10050
  static readonly SALES_PRM_MAILINGS_ITEMS_POST: number = 10051
  static readonly SALES_PRM_MAILINGS_ITEMS_GETITEM: number = 10052
  static readonly SALES_PRM_MAILINGS_ITEMS_PATCH: number = 10053
  static readonly SALES_PRM_MAILINGS_ITEMS_DELETE: number = 10054

  static readonly SALES_PRM_MAILINGS_RECIPIENTS_GETCOLLECTION: number = 10060
  static readonly SALES_PRM_MAILINGS_RECIPIENTS_POST: number = 10061
  static readonly SALES_PRM_MAILINGS_RECIPIENTS_GETITEM: number = 10062
  static readonly SALES_PRM_MAILINGS_RECIPIENTS_PATCH: number = 10063
  static readonly SALES_PRM_MAILINGS_RECIPIENTS_DELETE: number = 10064
}
